import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string, number } from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import NewProjectInfo from '~/components/ProjectInfo/New';
import Select from '~/components/Select';
import Toggle from '~/components/Toggle';
import DefaultInput from '~/components/Inputs/Default';
import PhoneInput from '~/components/Inputs/Phone';
import CurrencyInput from '~/components/Inputs/Currency';
import TableSelect from '~/components/TableSelect';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import TextArea from '~/components/TextArea';
import UploadRequestFiles from '~/components/UploadRequestFiles';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  CenterWrapper,
  DownloadButton,
  DownloadLink,
  LabelContainer,
  Label,
  BorderContent,
  FlexWrapper,
  AllPatternsButton,
  Table,
  ItemSequence,
  ItemContent,
  ItemWrapper,
  NoticeLabel,
  ButtonContainer,
} from './styles';

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
}

interface IProjectSponsorshipSheet {
  temp_id: string;
  amount: number;
  sheet_pattern: {
    value: string;
    label: string;
  };
  sheet_thickness: {
    value: string;
    label: string;
  };
  user_inclusion_id: string;
  user_update_id: string;
  conflict_error: string | undefined;
}

interface IFormProcedure1 {
  rebate_campaign: boolean;
  price: number;
  gross_discount_price: number;
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  sponsorship: {
    value: string;
  };
  erp_invoice_client_code: {
    value: string;
  };
  event_project?: {
    value: string;
  };
  record_description: string;
  record_note: string;
  project_sponsorship_sheets: IProjectSponsorshipSheet[];
}

interface ILocation {
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
}

const schema = Yup.object().shape({
  sponsorship: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  erp_invoice_client_code: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  client_contact_name: Yup.string().required('Campo obrigatório'),
  client_contact_phone: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Telefone/celular inválido')
    .max(15, 'Telefone/celular inválido'),
  client_contact_email: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido'),
  gross_discount_price: Yup.string().when(
    '$grossDiscountPriceRequired',
    (grossDiscountPriceRequired: string) => {
      if (grossDiscountPriceRequired) {
        return string().when((value: string) => {
          if (Number.isNaN(Number(parseFloat(value)))) {
            return string().required('Valor inválido');
          }
          if (parseFloat(value) <= 0) {
            return number()
              .min(0.1, 'Valor inválido')
              .required('Valor inválido')
              .typeError('Valor inválido');
          }
          return string().notRequired();
        });
      }

      return string().notRequired();
    },
  ),
});

const Procedure1: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [sponsorships, setSponsorships] = useState<
    {
      id: string;
      name: string;
      requires_req_files: boolean;
      requires_art_approval: boolean;
    }[]
  >();
  const [clients, setClients] = useState<IClient[]>();
  const [sheetPatterns, setSheetPatterns] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [sheetThicknesses, setSheetThicknesses] = useState<
    {
      id: string;
      description: string;
    }[]
  >();
  const [eventProjects, setEventProjects] = useState<
    {
      id: string;
      code: string;
    }[]
  >();
  const [projectSponsorshipSheets, setProjectSponsorshipSheets] = useState<
    IProjectSponsorshipSheet[]
  >([]);
  const [loadProjectSponsorshipSheets, setLoadProjectSponsorshipSheets] =
    useState(false);
  const [projectSponsorshipSheetsLoaded, setProjectSponsorshipSheetsLoaded] =
    useState(false);
  const [loadingSponsorships, setLoadingSponsorships] = useState<boolean>(true);
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [loadingSheetPatterns, setLoadingSheetPatterns] =
    useState<boolean>(true);
  const [loadingSheetThicknesses, setLoadingSheetThicknesses] =
    useState<boolean>(true);
  const [loadingProjectSponsorshipSheets, setLoadingProjectSponsorshipSheets] =
    useState<boolean>(true);
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const projectTemp = useRef(uuidv4());
  const projectRequestTemp = useRef(uuidv4());
  const [sponsorshipSheetsEnabled, setSponsorshipSheetsEnabled] =
    useState<boolean>(true);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenAllPatternsWarning, setIsOpenAllPatternsWarning] =
    useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [allPatterns, setAllPatterns] = useState<boolean>(false);
  const [grossDiscountPrice, setGrossDiscountPrice] = useState(0);

  const { handleSubmit, register, getValues, setValue, control, errors } =
    useForm<IFormProcedure1>({
      context: {
        grossDiscountPriceRequired: !sponsorshipSheetsEnabled,
      },
      resolver: yupResolver(schema),
      defaultValues: {
        rebate_campaign: false,
      },
    });

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_sponsorship_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (clients) {
      if (clients.length === 0 && state) {
        if (state.erp_client_code !== undefined) {
          setValue('erp_invoice_client_code', {
            value: state.erp_client_code,
          });
        }

        setSelectedClient({
          code:
            state.erp_client_code !== undefined ? state.erp_client_code : '',
          name:
            state.erp_client_name !== undefined ? state.erp_client_name : '',
          company_name:
            state.erp_client_company_name !== undefined
              ? state.erp_client_company_name
              : '',
          document:
            state.erp_client_document !== undefined
              ? state.erp_client_document
              : '',
          address:
            state.erp_client_address !== undefined
              ? state.erp_client_address
              : '',
          city:
            state.erp_client_city !== undefined ? state.erp_client_city : '',
          state:
            state.erp_client_state !== undefined ? state.erp_client_state : '',
        });
      }
    }
  }, [clients, state, setValue]);

  useEffect(() => {
    if (loadProjectSponsorshipSheets) {
      const firstProjectSponsorshipSheet: IProjectSponsorshipSheet = {
        temp_id: uuidv4(),
        amount: 0,
        sheet_pattern: { value: '', label: '' },
        sheet_thickness: { value: '', label: '' },
        user_inclusion_id: user.id,
        user_update_id: user.id,
        conflict_error: undefined,
      };

      setProjectSponsorshipSheets([firstProjectSponsorshipSheet]);
      setLoadProjectSponsorshipSheets(false);
      setProjectSponsorshipSheetsLoaded(true);
      setLoadingProjectSponsorshipSheets(false);
    }
  }, [state, user, loadProjectSponsorshipSheets]);

  useEffect(() => {
    if (!projectSponsorshipSheetsLoaded) {
      setLoadProjectSponsorshipSheets(true);
    }
  }, [projectSponsorshipSheetsLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Cliente não selecionado',
      });

      history.push('/projects/sponsorships');
    } else {
      register('erp_invoice_client_code');
      register('record_description');
      register('record_note');
      register('project_sponsorship_sheets');

      api.get('/items/sponsorships/valid').then(response => {
        setSponsorships(response.data);
        setLoadingSponsorships(false);
      });

      api
        .get(`/clients/clientgroup?code=${state.erp_client_code}`)
        .then(response => {
          setClients(response.data);
          setLoadingClients(false);
        });

      api.get('/sheetpatterns/valid').then(response => {
        setSheetPatterns(response.data);
        setLoadingSheetPatterns(false);
      });

      api.get('/sheetthicknesses/all').then(response => {
        setSheetThicknesses(response.data);
        setLoadingSheetThicknesses(false);
      });

      api
        .get(`/projects/eventprojectsforspons/${state.erp_client_code}`)
        .then(response => {
          setEventProjects(response.data);
        });
    }
  }, [addToast, history, state, user, register, setValue]);

  const sponsorshipOptions = sponsorships?.map(sponsorship => ({
    value: sponsorship.id,
    label: sponsorship.name,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label: `(${client.code} - ${client.document}) - ${client.company_name}`,
  }));

  const sheetPatternOptions = sheetPatterns?.map(sheetPattern => ({
    value: sheetPattern.id,
    label: sheetPattern.name,
  }));

  const sheetThicknessOptions = sheetThicknesses?.map(sheetThickness => ({
    value: sheetThickness.id,
    label: sheetThickness.description,
  }));

  const submitForm = useCallback(
    async (data: IFormProcedure1) => {
      try {
        setIsSaving(true);

        await api.post('/projects', {
          ...data,
          erp_client_code: state.erp_client_code,
          erp_rep_code: state.erp_rep_code,
          project_request_temp_id: projectRequestTemp.current,
          project_temp_id: projectTemp.current,
          rep_as_client: false,
          sponsorship_art_approval:
            sponsorships?.find(s => s.id === data.sponsorship.value)
              ?.requires_art_approval || false,
          type: 'PATROCINIO',
        });

        addToast({
          type: 'success',
          title: 'Solicitação iniciada!',
          description: 'Abertura de solicitação realizada com sucesso.',
        });

        history.push('/projects/sponsorships');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, projectRequestTemp, projectTemp, sponsorships, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    let emptyRequiredReqFiles = false;

    if (
      sponsorships?.find(s => s.id === getValues('sponsorship').value)
        ?.requires_req_files
    ) {
      await api
        .get(
          `/projectrequestfilesreceived` +
            `/projectrequesttemp?` +
            `project_request_temp_id=${projectRequestTemp.current}`,
        )
        .then(response => {
          emptyRequiredReqFiles = response.data.length <= 0;
        });
    }

    if (emptyRequiredReqFiles) {
      setTitleNotice('Necessário anexar arquivos de requisição!');
      setSubTitleNotice(
        'Não é possível enviar solicitação sem anexar arquivos.',
      );
      setIsOpenNotice(true);
    } else if (
      sponsorshipSheetsEnabled &&
      projectSponsorshipSheets.length <= 0
    ) {
      setTitleNotice('Chapas inválidas!');
      setSubTitleNotice('Necessário informar chapas!');
      setIsOpenNotice(true);
    } else if (
      sponsorshipSheetsEnabled &&
      projectSponsorshipSheets.filter(
        projSponsorshipSheet => projSponsorshipSheet.sheet_pattern.value === '',
      ).length > 0
    ) {
      setTitleNotice('Padrões inválidos em chapas!');
      setSubTitleNotice(
        'Não é possível enviar solicitação com ' +
          'registro de chapas sem padrão selecionado!',
      );
      setIsOpenNotice(true);
    } else if (
      sponsorshipSheetsEnabled &&
      projectSponsorshipSheets.filter(
        projSponsorshipSheet =>
          projSponsorshipSheet.sheet_thickness.value === '',
      ).length > 0
    ) {
      setTitleNotice('Espessuras inválidas em chapas!');
      setSubTitleNotice(
        'Não é possível enviar solicitação com ' +
          'registro de chapas sem espessura selecionada!',
      );
      setIsOpenNotice(true);
    } else if (
      sponsorshipSheetsEnabled &&
      projectSponsorshipSheets.filter(
        projSponsorshipSheet => projSponsorshipSheet.amount <= 0,
      ).length > 0
    ) {
      setTitleNotice('Quantidades inválidas em chapas!');
      setSubTitleNotice(
        'Não é possível enviar solicitação com ' +
          'registro de chapas sem quantidade informada!',
      );
      setIsOpenNotice(true);
    } else {
      if (grossDiscountPrice > 0) {
        setTitleConfirmProcedure(
          `Confirma abertura da solicitação, com desconto no valor de ${new Intl.NumberFormat(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          ).format(grossDiscountPrice)}?`,
        );
      } else {
        setTitleConfirmProcedure(`Confirma abertura da solicitação?`);
      }

      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    const noteValue = getValues('note');

    if (sponsorshipSheetsEnabled) {
      setValue('project_sponsorship_sheets', projectSponsorshipSheets);
    } else {
      setValue('project_sponsorship_sheets', undefined);
    }

    setValue('record_note', noteValue);

    setValue('gross_discount_price', grossDiscountPrice);

    if (grossDiscountPrice > 0) {
      setValue(
        'record_description',
        `Abertura de solicitação, com desconto no valor de ${new Intl.NumberFormat(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        ).format(grossDiscountPrice)}`,
      );
    } else {
      setValue(
        'record_description',
        `Abertura de solicitação, com registro de chapas, sem valor de desconto`,
      );
    }

    handleSubmit(submitForm)();
  }

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);
    api.delete(
      `projectrequestfilesreceived/projectrequesttempid/${projectRequestTemp.current}`,
    );

    history.push('/projects/sponsorships');
  };

  function handleGrossDiscountPriceChange(valueReceived: string): void {
    const grossDiscountPriceValue = valueReceived;

    let grossDiscountPriceConv = grossDiscountPriceValue.toString();
    grossDiscountPriceConv = grossDiscountPriceConv.replaceAll('R$', '');
    grossDiscountPriceConv = grossDiscountPriceConv.replaceAll('.', '');
    grossDiscountPriceConv = grossDiscountPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(grossDiscountPriceConv)))) {
      setGrossDiscountPrice(parseFloat(grossDiscountPriceConv));
    } else {
      setGrossDiscountPrice(0);
    }
  }

  useEffect(() => {
    if (allPatterns && sheetPatterns && projectSponsorshipSheets) {
      setAllPatterns(false);

      const addSheetPatterns: { id: string; name: string }[] = [];
      const tempProjectSponsorshipSheets: IProjectSponsorshipSheet[] = [];

      Object.keys(projectSponsorshipSheets).forEach(
        projectSponsorshipSheetKey => {
          tempProjectSponsorshipSheets.push(
            projectSponsorshipSheets[Number(projectSponsorshipSheetKey)],
          );
        },
      );

      Object.keys(sheetPatterns).forEach(sheetPatternKey => {
        let foundSheetPattern = false;

        Object.keys(projectSponsorshipSheets).forEach(
          projectSponsorshipSheetKey => {
            if (
              sheetPatterns[Number(sheetPatternKey)].id ===
              projectSponsorshipSheets[Number(projectSponsorshipSheetKey)]
                .sheet_pattern.value
            ) {
              foundSheetPattern = true;
            }
          },
        );

        if (!foundSheetPattern) {
          addSheetPatterns.push(sheetPatterns[Number(sheetPatternKey)]);
        }
      });

      Object.keys(addSheetPatterns).forEach(addSheetPatternKey => {
        const newProjectSponsorshipSheet: IProjectSponsorshipSheet = {
          temp_id: uuidv4(),
          amount: 0,
          sheet_pattern: {
            value: addSheetPatterns[Number(addSheetPatternKey)].id,
            label: addSheetPatterns[Number(addSheetPatternKey)].name,
          },
          sheet_thickness: { value: '', label: '' },
          user_inclusion_id: user.id,
          user_update_id: user.id,
          conflict_error: undefined,
        };

        tempProjectSponsorshipSheets.push(newProjectSponsorshipSheet);
      });

      tempProjectSponsorshipSheets.sort((a, b) =>
        a.sheet_pattern.label.localeCompare(b.sheet_pattern.label),
      );

      setProjectSponsorshipSheets(
        tempProjectSponsorshipSheets.filter(
          tempProjSponsorshipSheet =>
            tempProjSponsorshipSheet.sheet_pattern.value !== '' ||
            tempProjSponsorshipSheet.sheet_thickness.value !== '' ||
            tempProjSponsorshipSheet.amount > 0,
        ),
      );
    }
  }, [allPatterns, sheetPatterns, projectSponsorshipSheets, user]);

  function handleAddRowTableProjectSponsorshipSheets(): void {
    const newProjectSponsorshipSheet: IProjectSponsorshipSheet = {
      temp_id: uuidv4(),
      amount: 0,
      sheet_pattern: { value: '', label: '' },
      sheet_thickness: { value: '', label: '' },
      user_inclusion_id: user.id,
      user_update_id: user.id,
      conflict_error: undefined,
    };

    setProjectSponsorshipSheets(prevProjectSponsorshipSheets => [
      ...prevProjectSponsorshipSheets,
      newProjectSponsorshipSheet,
    ]);
  }

  function handleDeleteRowTableProjectSponsorshipSheets(temp_id: string): void {
    setProjectSponsorshipSheets(
      projectSponsorshipSheets.filter(
        projSponsorshipSheet => projSponsorshipSheet.temp_id !== temp_id,
      ),
    );
  }

  useEffect(() => {
    Object.keys(projectSponsorshipSheets).forEach(key => {
      let conf_error: string | undefined;

      const projSponsorshipSheetConflict = projectSponsorshipSheets.filter(
        projectSponsorshipSheet =>
          projectSponsorshipSheet.temp_id !==
            projectSponsorshipSheets[Number(key)].temp_id &&
          projectSponsorshipSheet.sheet_pattern.value !== '' &&
          projectSponsorshipSheet.sheet_thickness.value !== '' &&
          projectSponsorshipSheet.sheet_pattern.value ===
            projectSponsorshipSheets[Number(key)].sheet_pattern.value &&
          projectSponsorshipSheet.sheet_thickness.value ===
            projectSponsorshipSheets[Number(key)].sheet_thickness.value,
      );

      if (
        projSponsorshipSheetConflict &&
        projSponsorshipSheetConflict.length > 0
      ) {
        conf_error = 'Padrão e Espessura já registrados';
      } else {
        conf_error = undefined;
      }

      if (projectSponsorshipSheets[Number(key)].conflict_error !== conf_error) {
        setProjectSponsorshipSheets(
          projectSponsorshipSheets.map(projSponsorshipSheet => {
            if (
              projSponsorshipSheet.temp_id ===
              projectSponsorshipSheets[Number(key)].temp_id
            ) {
              projSponsorshipSheet.conflict_error = conf_error;
            }

            return projSponsorshipSheet;
          }),
        );
      }
    });
  }, [projectSponsorshipSheets]);

  function handleRowSheetPatternChange(
    temp_id: string,
    fieldValue: string,
    fieldLabel: string,
  ): void {
    setProjectSponsorshipSheets(
      projectSponsorshipSheets.map(projSponsorshipSheet => {
        if (projSponsorshipSheet.temp_id === temp_id) {
          projSponsorshipSheet.sheet_pattern.value = fieldValue;
          projSponsorshipSheet.sheet_pattern.label = fieldLabel;
        }
        return projSponsorshipSheet;
      }),
    );
  }

  function handleRowSheetThicknessChange(
    temp_id: string,
    fieldValue: string,
    fieldLabel: string,
  ): void {
    setProjectSponsorshipSheets(
      projectSponsorshipSheets.map(projSponsorshipSheet => {
        if (projSponsorshipSheet.temp_id === temp_id) {
          projSponsorshipSheet.sheet_thickness.value = fieldValue;
          projSponsorshipSheet.sheet_thickness.label = fieldLabel;
        }
        return projSponsorshipSheet;
      }),
    );
  }

  function handleRowAmountChange(temp_id: string, fieldValue: number): void {
    let amountConv = fieldValue.toString();
    let amountValue = 0;

    amountConv = amountConv.replaceAll('.', '');
    amountConv = amountConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(amountConv)))) {
      amountValue = parseFloat(amountConv);
    }

    setProjectSponsorshipSheets(
      projectSponsorshipSheets.map(projSponsorshipSheet => {
        if (projSponsorshipSheet.temp_id === temp_id) {
          projSponsorshipSheet.amount = amountValue;
        }
        return projSponsorshipSheet;
      }),
    );
  }

  function handleInvoiceClient(): React.ReactNode | null {
    if (clients) {
      if (clients.length > 0) {
        return (
          <Content>
            <Wrapper style={{ width: '50%' }}>
              <Select
                label="Cliente Faturamento"
                options={clientOptions}
                loading={loadingClients}
                error={errors?.erp_invoice_client_code?.value?.message}
                onSet={(e: any) => {
                  setValue(
                    'erp_invoice_client_code',
                    {
                      value: e.value,
                    },
                    {
                      shouldValidate: true,
                    },
                  );

                  const selClient = clients.find(cli => cli.code === e.value);

                  if (selClient) {
                    setSelectedClient({
                      code: selClient.code,
                      name: selClient.name,
                      company_name: selClient.company_name,
                      document: selClient.document,
                      address: selClient.address,
                      city: selClient.city,
                      state: selClient.state,
                    });
                  }
                }}
              />
            </Wrapper>
          </Content>
        );
      }

      return (
        <Content>
          <Wrapper style={{ width: '70%' }}>
            <DefaultInput
              name="erp_invoice_client_code_def"
              labelText="Cliente Faturamento"
              type="text"
              value={`(${selectedClient?.code}) - ${selectedClient?.company_name}`}
              disabled
            />
          </Wrapper>
        </Content>
      );
    }

    return null;
  }

  function handleProjectSponsorshipSheetsTable(): React.ReactNode {
    if (projectSponsorshipSheets) {
      if (projectSponsorshipSheets.length > 0) {
        return (
          <Table>
            {loadingProjectSponsorshipSheets && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectSponsorshipSheets?.map(projectSponsorshipSheet => (
              <tr key={projectSponsorshipSheet.temp_id}>
                <ItemSequence />

                <ItemContent>
                  <ItemWrapper>
                    <TableSelect
                      label="Padrão"
                      upperCaseLabel
                      options={sheetPatternOptions}
                      loading={loadingSheetPatterns}
                      defaultValue={{
                        value: projectSponsorshipSheet.sheet_pattern.value,
                        label: projectSponsorshipSheet.sheet_pattern.label
                          ? projectSponsorshipSheet.sheet_pattern.label
                          : 'Selecione ...',
                      }}
                      error={projectSponsorshipSheet.conflict_error}
                      onSet={(e: any) => {
                        handleRowSheetPatternChange(
                          projectSponsorshipSheet.temp_id,
                          e.value,
                          e.label,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableSelect
                      label="Espessura"
                      upperCaseLabel
                      options={sheetThicknessOptions}
                      loading={loadingSheetThicknesses}
                      defaultValue={{
                        value: projectSponsorshipSheet.sheet_thickness.value,
                        label: projectSponsorshipSheet.sheet_thickness.label
                          ? projectSponsorshipSheet.sheet_thickness.label
                          : 'Selecione ...',
                      }}
                      error={projectSponsorshipSheet.conflict_error}
                      onSet={(e: any) => {
                        handleRowSheetThicknessChange(
                          projectSponsorshipSheet.temp_id,
                          e.value,
                          e.label,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableWholeNumberInput
                      type="text"
                      labelText="Quantidade"
                      upperCaseLabel
                      defaultValue={projectSponsorshipSheet.amount}
                      onSet={e => {
                        handleRowAmountChange(
                          projectSponsorshipSheet.temp_id,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px 5px 10px 10px',
                  }}
                >
                  <DeleteRowButton
                    type="button"
                    onClick={() => {
                      handleDeleteRowTableProjectSponsorshipSheets(
                        projectSponsorshipSheet.temp_id,
                      );
                    }}
                  />
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  function handleProjectSponsorshipSheets(): React.ReactNode {
    if (sponsorshipSheetsEnabled) {
      return (
        <>
          <Content>
            <CenterWrapper>
              <AllPatternsButton
                type="button"
                onClick={() => setIsOpenAllPatternsWarning(true)}
              >
                Linha Completa
              </AllPatternsButton>
            </CenterWrapper>
          </Content>

          <LabelContainer>
            <Label>Chapas de MDF</Label>
          </LabelContainer>

          <Content>{handleProjectSponsorshipSheetsTable()}</Content>

          <ContentAddRow>
            <AddRowButton
              type="button"
              onClick={() => handleAddRowTableProjectSponsorshipSheets()}
            />
          </ContentAddRow>
        </>
      );
    }

    return <></>;
  }

  function handleFiles(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <Content style={{ maxWidth: 700 }}>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp.current}
            project_type="PATROCINIO"
            sequence={1}
            user_id={user.id}
          />
        </Content>
      );
    }

    return <></>;
  }

  function getDownloadLink(): string {
    return (
      `${process.env.REACT_APP_API_URL}/downloads/` +
      `projects/sponsorships/Logomarca_e_Regras_de_Uso.zip`
    );
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Nova Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <NewProjectInfo
        rep_as_client={false}
        erp_rep_code={state?.erp_rep_code}
        erp_client_code={state?.erp_client_code}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 680 }}>
          <Wrapper style={{ width: 230 }}>
            <Controller
              name="sponsorship"
              control={control}
              options={sponsorshipOptions}
              label="Tipo de Pagamento"
              error={errors?.sponsorship?.value?.message}
              as={Select}
              loading={loadingSponsorships}
            />
          </Wrapper>

          <Wrapper style={{ width: 220 }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Campanha de Rebate"
                />
              )}
              name="rebate_campaign"
              defaultValue
              control={control}
            />
          </Wrapper>

          {eventProjects && eventProjects.length > 0 && (
            <Wrapper style={{ width: 230 }}>
              <Controller
                name="event_project"
                control={control}
                options={eventProjects.map(e_p => ({
                  value: e_p.id,
                  label: e_p.code,
                }))}
                label="Solicitação de Evento"
                error={errors?.event_project?.value?.message}
                as={Select}
                loading={!eventProjects}
              />
            </Wrapper>
          )}
        </div>

        <Content style={{ marginTop: 20 }}>
          <CenterWrapper>
            <DownloadButton type="button">
              <DownloadLink href={getDownloadLink()} target="_blank" download>
                BAIXAR LOGOMARCA E REGRAS DE APLICAÇÃO
              </DownloadLink>
            </DownloadButton>
          </CenterWrapper>
        </Content>

        {handleInvoiceClient()}

        <LabelContainer>
          <Label>Informações Cliente Faturamento</Label>
        </LabelContainer>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_name"
                labelFor="erp_invoice_client_name"
                labelText="Razão Social"
                type="text"
                value={selectedClient?.company_name}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <DefaultInput
                name="erp_invoice_client_document"
                labelFor="erp_invoice_client_document"
                labelText="CNPJ"
                type="text"
                value={selectedClient?.document}
                disabled
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_address"
                labelFor="erp_invoice_client_address"
                labelText="Endereço"
                type="text"
                value={selectedClient?.address}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '35%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_city"
                labelFor="erp_invoice_client_city"
                labelText="Cidade"
                type="text"
                value={selectedClient?.city}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '15%' }}>
              <DefaultInput
                name="erp_invoice_client_state"
                labelFor="erp_invoice_client_state"
                labelText="Estado"
                type="text"
                value={selectedClient?.state}
                disabled
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <LabelContainer>
          <Label>Contato da Loja</Label>
        </LabelContainer>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="client_contact_name"
                labelFor="client_contact_name"
                labelText="Nome"
                type="text"
                error={errors?.client_contact_name?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <PhoneInput
                name="client_contact_phone"
                labelFor="client_contact_phone"
                labelText="Telefone"
                type="text"
                error={errors?.client_contact_phone?.message}
                ref={register}
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '100%' }}>
              <DefaultInput
                name="client_contact_email"
                labelFor="client_contact_email"
                labelText="E-mail"
                type="text"
                error={errors?.client_contact_email?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <Content>
          <Wrapper style={{ width: '50%' }}>
            <CurrencyInput
              name="gross_discount_price"
              labelFor="gross_discount_price"
              labelText="Valor Solicitado"
              defaultValue={0}
              error={errors?.gross_discount_price?.message}
              ref={register}
              onSet={e => {
                handleGrossDiscountPriceChange(e.target.value);
              }}
            />
          </Wrapper>
        </Content>

        <Content>
          <CenterWrapper>
            <Toggle
              onChange={() => {
                setSponsorshipSheetsEnabled(!sponsorshipSheetsEnabled);
              }}
              checked={sponsorshipSheetsEnabled}
              label="Registra Chapas de MDF"
            />
          </CenterWrapper>
        </Content>

        {handleProjectSponsorshipSheets()}

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="note"
              labelFor="note"
              labelText="Observação"
              error={errors?.note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadRequestFiles
            type="requisicao"
            project_request_temp_id={projectRequestTemp.current}
            project_type="PATROCINIO"
            sequence={1}
            user_id={user.id}
          />
        </Content>

        {handleFiles()}

        <Content>
          <CenterWrapper>
            <NoticeLabel>
              O PATROCÍNIO SERÁ CONCEDIDO MEDIANTE DESCONTO EM DUPLICATA APÓS O
              ENVIO DAS FOTOS COM OS DEVIDOS ABATIMENTOS FISCAIS.
            </NoticeLabel>
          </CenterWrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="A solicitação será enviada para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenAllPatternsWarning}
        setIsOpen={setIsOpenAllPatternsWarning}
        onConfirm={() => setAllPatterns(true)}
        title="Deseja realmente preencher com todos os padrões?"
        opConfirm="Sim"
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure1;
