import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import AnimatedCard from './Cards/AnimatedCard';
import StaticCard from './Cards/StaticCard';
import AnimatedDottedLine from './Lines/AnimatedDottedLine';
import StaticDottedLine from './Lines/StaticDottedLine';
import StaticLine from './Lines/StaticLine';

interface IStage {
  sequence: number;
  current: boolean;
  pending: boolean;
  description: string;
  user?: {
    name: string;
  };
}

interface IProjStage {
  sequence: number;
  proc_sequence: number;
  project_procedure: {
    description: string;
  };
  user: {
    name: string;
  };
}

interface IProjProc {
  sequence: number;
  description: string;
}

interface IStagesProps {
  project_id: string;
  type: string;
  current_seq: number;
  status: string;
}

const Stages: React.FC<IStagesProps> = ({
  project_id,
  type,
  current_seq,
  status,
}) => {
  const [stages, setStages] = useState<IStage[]>([]);

  useEffect(() => {
    const loadStagesAndProcedures = async () => {
      let tempStages: IStage[] = [];

      await api
        .get<IProjStage[]>(`/projectstages/project/${project_id}`)
        .then(response => {
          response.data.forEach(p_s => {
            tempStages = [
              ...tempStages,
              {
                sequence: p_s.sequence,
                current: false,
                pending: false,
                description: p_s.project_procedure.description,
                user: {
                  name: p_s.user.name,
                },
              },
            ];
          });
        });

      if (status === 'Pendente') {
        await api
          .get<IProjProc[]>(`/projectprocedures/type/${type}`)
          .then(response => {
            response.data
              .filter(p_p => p_p.sequence >= current_seq)
              .forEach(p_p => {
                tempStages = [
                  ...tempStages,
                  {
                    sequence: p_p.sequence,
                    current: p_p.sequence === current_seq,
                    pending: true,
                    description: p_p.description,
                  },
                ];
              });
          });
      }

      setStages(tempStages);
    };

    loadStagesAndProcedures();
  }, [project_id, type, current_seq, status]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        {stages.map((stage, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {stage.current ? (
              <AnimatedCard description={stage.description} />
            ) : (
              <StaticCard
                description={stage.description}
                pending={stage.pending}
                user_name={stage.user?.name}
              />
            )}

            {index === stages.length - 1 ? null : stages[index + 1]?.current ? (
              <AnimatedDottedLine />
            ) : stage.pending ? (
              <StaticDottedLine />
            ) : (
              <StaticLine />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stages;
