import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import Select from '~/components/Select';
import Toggle from '~/components/Toggle';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import RejectButton from '~/components/Buttons/Reject';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import RejectProjectModal from '~/components/Modals/RejectProject';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, Wrapper, ButtonContainer } from './styles';

interface IFormProcedure2 {
  sponsorship_art_approval: boolean;
  sponsorship_completion_files: boolean;
  validation_type: string;
  record_description: string;
  record_note: string;
  record_reason: string;
}

interface ISponsorship {
  id: string;
  name: string;
  requires_req_files: boolean;
  requires_art_approval: boolean;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  gross_discount_price: number;
  erp_rep_code: string;
  status: string;
  sponsorship_art_approval: boolean;
  sponsorship: ISponsorship;
  project_procedure_id: string;
}

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [validationType, setValidationType] = useState('APROVADO');
  const [sponsorships, setSponsorships] = useState<ISponsorship[]>();
  const [selectedSponsorship, setSelectedSponsorship] = useState<ISponsorship>(
    state.sponsorship,
  );
  const projectTemp = useRef(uuidv4());
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenRejectProject, setIsOpenRejectProject] = useState(false);
  const [reasonRejectProject, setReasonRejectProject] = useState('');
  const [rejectionConfirmed, setRejectionConfirmed] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, getValues, setValue, control, errors } =
    useForm<IFormProcedure2>({
      defaultValues: {
        sponsorship_art_approval: true,
        sponsorship_completion_files: true,
      },
    });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/sponsorships');
    } else {
      register('validation_type');
      register('record_reason');
      register('record_description');

      api.get('/items/sponsorships/valid').then(response => {
        setSponsorships(response.data);
      });
    }
  }, [addToast, history, state, user, register, setValue]);

  useEffect(() => {
    setValue('validation_type', validationType);
    if (validationType === 'CANCELADO') {
      setRejectionConfirmed(true);
    }
  }, [validationType, setValue]);

  useEffect(() => {
    setValue('record_reason', reasonRejectProject);
  }, [reasonRejectProject, setValue, register]);

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, {
          ...data,
          code: state.code,
          project_procedure_id: state.project_procedure_id,
          project_temp_id: projectTemp.current,
          sponsorship_id: selectedSponsorship.id,
          type: 'PATROCINIO',
        });

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/sponsorships');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, projectTemp, selectedSponsorship, state],
  );

  useEffect(() => {
    if (rejectionConfirmed) {
      setValue('record_description', 'Rejeição Marketing');
      handleSubmit(submitForm)();
    }
  }, [rejectionConfirmed, handleSubmit, submitForm, getValues, setValue]);

  function onConfirmProcedure(): void {
    if (state.gross_discount_price > 0) {
      setValue(
        'record_description',
        `Aprovação Marketing, desconto no valor de ${new Intl.NumberFormat(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        ).format(state.gross_discount_price)}`,
      );
    } else {
      setValue('record_description', `Aprovação Marketing`);
    }

    handleSubmit(submitForm)();
  }

  function onRejectProject(): void {
    setValidationType('CANCELADO');
  }

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);

    history.push('/projects/sponsorships');
  };

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content style={{ margin: '2rem 0 3rem 0' }}>
          <Wrapper style={{ width: 250 }}>
            <Select
              name="sponsorship"
              label="Tipo de Pagamento"
              options={sponsorships?.map(s => ({
                value: s.id,
                label: s.name,
              }))}
              value={{
                value: selectedSponsorship.id,
                label: selectedSponsorship.name,
              }}
              loading={!sponsorships}
              onSet={(e: any) => {
                const findSpons = sponsorships?.find(s => s.id === e.value);

                if (findSpons) {
                  setSelectedSponsorship(findSpons);

                  if (findSpons.requires_art_approval) {
                    setValue('sponsorship_art_approval', true);
                  }
                }
              }}
            />
          </Wrapper>
        </Content>

        <Content style={{ marginBottom: '3rem', rowGap: '2rem' }}>
          <Wrapper style={{ width: '50%' }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Necessita de Aprovação de Arte"
                  isDisabled={selectedSponsorship.requires_art_approval}
                />
              )}
              name="sponsorship_art_approval"
              defaultValue
              control={control}
            />
          </Wrapper>

          <Wrapper style={{ width: '50%' }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Necessita de Anexos de Conclusão"
                />
              )}
              name="sponsorship_completion_files"
              defaultValue
              control={control}
            />
          </Wrapper>
        </Content>

        <Content style={{ marginBottom: '2rem' }}>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content style={{ marginBottom: '2rem' }}>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp.current}
            project_type="PATROCINIO"
            sequence={2}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            label="Aprovar"
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />

          <RejectButton
            style={{ marginLeft: '1rem' }}
            type="button"
            disabled={isSaving}
            onClick={() => {
              setIsOpenRejectProject(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={`Confirma aprovação da solicitação ${state.code}?`}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <RejectProjectModal
        isOpen={isOpenRejectProject}
        onReject={onRejectProject}
        title={`Deseja realmente recusar a solicitação ${state.code}?`}
        subTitle={
          'A solicitação será cancelada e esta ' +
          'operação não poderá ser desfeita!'
        }
        setIsOpen={setIsOpenRejectProject}
        setReason={setReasonRejectProject}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure2;
