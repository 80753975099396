import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    margin: 2.4rem 0;
  `}
`;

export const TabTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.large};
    font-weight: bold;
    justify-content: center;
    margin-left: 0.5rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;

  @media (max-width: 1010px) {
    float: right;
    flex-direction: column;
  }
`;
