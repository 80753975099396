import styled, { css } from 'styled-components';

export const Card = styled.div`
  ${({ theme }) => css`
    width: 220px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    color: ${theme.colors.veryDarkGrey};
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    position: relative;
  `}
`;
