import styled, { css } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

interface ICardProps {
  pending: boolean;
}

export const Card = styled.div<ICardProps>`
  ${({ theme, pending }) => css`
    width: 220px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    color: ${theme.colors.veryDarkGrey};
    font-size: 14px;
    text-align: center;

    ${pending
      ? css`
          border: 3px dashed ${theme.colors.grey};
        `
      : css`
          border: 1px solid ${theme.colors.grey};
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
          cursor: pointer;
        `}
  `}
`;

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '1.5rem',
    padding: '10px',
  },
})(Tooltip);
