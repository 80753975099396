import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    min-height: 300px;
    margin: 2.4rem 0;
    padding: 2.4rem;
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
  `}
`;

export const Wrapper = styled.div`
  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding-top: 2.8rem;

  @media (max-width: 900px) {
    padding-top: 0;
  }
`;
