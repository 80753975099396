import React from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../../_layouts/default';

import ChooseProcess from './ChooseProcess';
import Cancel from './Cancel';
import ClientContact from './ClientContact';
import DiscountCancellation from './DiscountCancellation';
import ErpRepClient from './ErpRepClient';
import EventDate from './EventDate';
import Status from './Status';

const ProjectEditing: React.FC = () => {
  const { pathname } = useLocation();

  function getComponent(): React.ReactNode {
    if (pathname === '/management/projectediting/cancel') {
      return <Cancel />;
    }

    if (pathname === '/management/projectediting/clientcontact') {
      return <ClientContact />;
    }

    if (pathname === '/management/projectediting/discountcancellation') {
      return <DiscountCancellation />;
    }

    if (pathname === '/management/projectediting/erprepclient') {
      return <ErpRepClient />;
    }

    if (pathname === '/management/projectediting/eventdate') {
      return <EventDate />;
    }

    if (pathname === '/management/projectediting/status') {
      return <Status />;
    }

    return <ChooseProcess />;
  }

  return <DefaultLayout>{getComponent()}</DefaultLayout>;
};

export default ProjectEditing;
