import React from 'react';

import { Card } from './styles';

interface IAnimatedCardProps {
  description: string;
}

const AnimatedCard: React.FC<IAnimatedCardProps> = ({ description }) => {
  return (
    <Card>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 220 120"
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        <rect
          x="4"
          y="4"
          width="212"
          height="112"
          fill="none"
          stroke="#747474"
          strokeWidth="3"
          strokeDasharray="10 5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="30"
            dur="2s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>

      {description}
    </Card>
  );
};

export default AnimatedCard;
