import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTools, FaWrench } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import DefaultInput from '~/components/Inputs/Default';
import DateInput from '~/components/Inputs/Date';
import TextArea from '~/components/TextArea';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Wrapper } from './styles';

interface IFormEventDate {
  event_date: Date;
  reason: string;
  received_user_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  event_date: Date;
}

const schema = Yup.object().shape({
  event_date: Yup.date()
    .min(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
      'Data inválida',
    )
    .max(
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1825),
      'Data inválida',
    )
    .typeError('Campo obrigatório'),
  reason: Yup.string().required('Campo obrigatório'),
});

const EventDate: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue, errors } = useForm<IFormEventDate>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/management/main');
    } else {
      register('received_user_id');

      setValue('received_user_id', user.id);
    }
  }, [state, addToast, history, user, register, setValue]);

  const submitForm = useCallback(
    async (data: IFormEventDate) => {
      try {
        setIsSaving(true);

        if (state && state.id) {
          await api.put(`/projects/updateeventdate/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Solicitação atualizada!',
            description: 'Solicitação atualizada com sucesso.',
          });
        }

        history.push('/management/main');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [state, addToast, history],
  );

  function onConfirmProcedure(): void {
    handleSubmit(submitForm)();
  }

  const onExit = () => {
    history.push('/management/main');
  };

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Alteração Manual de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaWrench}
        mainColored
      />

      <ProjectInfo project_id={state.id} all />

      <form onSubmit={handleSubmit(submitForm)}>
        <div
          style={{ maxWidth: 680, display: 'flex', flexWrap: 'wrap', gap: 10 }}
        >
          <Wrapper style={{ width: 230 }}>
            <DefaultInput
              name="old_event_date"
              labelFor="old_event_date"
              labelText="Data Evento Antiga"
              type="text"
              value={new Date(state?.event_date).toLocaleDateString()}
              disabled
            />
          </Wrapper>

          <Wrapper style={{ width: 230 }}>
            <DateInput
              name="event_date"
              labelFor="event_date"
              labelText="Data Evento"
              type="date"
              error={errors?.event_date?.message}
              ref={register}
            />
          </Wrapper>
        </div>

        <div style={{ maxWidth: 680, marginTop: 20 }}>
          <Wrapper>
            <TextArea
              name="reason"
              labelFor="reason"
              labelText="Motivo"
              error={errors?.reason?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
        >
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </div>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={
          `Confirma alteração de data de ` +
          `evento na solicitação ${state.code}?`
        }
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default EventDate;
