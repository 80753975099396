import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SyncLoader } from 'react-spinners';
import { FaEnvelope, FaCheckCircle } from 'react-icons/fa';
import * as Yup from 'yup';

import Button from '~/components/Button';
import Input from '~/components/Input';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import logoImg from '~/assets/images/logo.png';

import {
  Container,
  Wrapper,
  Content,
  SuccessContent,
  AnimationContainer,
  InstructionsWrapper,
  SuccessInfoWrapper,
  SuccessIconWrapper,
  ButtonWrapper,
  Background,
} from './styles';

interface IFormRequest {
  email: string;
}

const schema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
});

const Request: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { handleSubmit, register, errors } = useForm<IFormRequest>({
    resolver: yupResolver(schema),
  });

  const submitForm = useCallback(
    async (data: IFormRequest) => {
      try {
        await api.post(`/password/request`, data);

        setLoading(false);
        setSuccess(true);
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description: error.response.data.message,
        });
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (errors.email?.message !== undefined) {
      setLoading(false);
    }
  }, [errors]);

  return (
    <Container>
      <Wrapper>
        <Background />
        <AnimationContainer>
          <img src={logoImg} alt="Guararapes Logo" />

          {success ? (
            <SuccessContent>
              <SuccessIconWrapper>
                <FaCheckCircle size={100} />
              </SuccessIconWrapper>
              <SuccessInfoWrapper>
                Solicitação efetuada com sucesso, as instruções para redefinição
                de senha foram enviadas por e-mail.
              </SuccessInfoWrapper>
            </SuccessContent>
          ) : (
            <Content>
              <form onSubmit={handleSubmit(submitForm)}>
                <InstructionsWrapper>
                  Informe o endereço de e-mail do cadastro e clique em enviar,
                  para receber as instruções de redefinição de senha no e-mail
                  informado.
                </InstructionsWrapper>
                <Input
                  name="email"
                  type="text"
                  labelText="E-mail"
                  icon={FaEnvelope}
                  ref={register}
                  error={errors.email?.message}
                />
                <ButtonWrapper>
                  <Button
                    id="btn-send"
                    type="submit"
                    onClick={() => {
                      setLoading(!loading);
                    }}
                  >
                    {loading ? (
                      <SyncLoader size={8} color="#FFF" margin={1} />
                    ) : (
                      'Enviar'
                    )}
                  </Button>
                </ButtonWrapper>
              </form>
            </Content>
          )}
        </AnimationContainer>
      </Wrapper>
    </Container>
  );
};

export default Request;
