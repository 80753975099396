import React from 'react';

const StaticDottedLine: React.FC = () => {
  return (
    <svg width="50" height="100" viewBox="0 0 50 100">
      <line
        x1="25"
        y1="10"
        x2="25"
        y2="90"
        stroke="#adadad"
        strokeWidth="4"
        strokeDasharray="5 10"
      />
    </svg>
  );
};

export default StaticDottedLine;
