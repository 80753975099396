import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTools, FaWrench } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import Select from '~/components/Select';
import SelectButton from '~/components/Buttons/Select';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import { Container, Wrapper, ButtonContainer } from './styles';

interface IProject {
  id: string;
  type: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  net_discount_price: number;
  granted_discount_price: number;
  canceled_discount_price: number;
  event_date: Date;
  status: string;
  sponsorship?: {
    id: string;
    name: string;
  };
}

const TYPE_OPTIONS = [
  { value: 'cancel', label: 'Cancelamento', adm_perm: false },
  {
    value: 'discount_cancellation',
    label: 'Cancelamento de Desconto',
    adm_perm: true,
  },
  { value: 'client_contact', label: 'Contato de Cliente', adm_perm: false },
  { value: 'event_date', label: 'Data de Evento', adm_perm: false },
  { value: 'erp_rep_client', label: 'Representante/Cliente', adm_perm: true },
  { value: 'status', label: 'Status', adm_perm: true },
];

const ChooseProcess: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [projects, setProjects] = useState<IProject[]>();
  const project = useRef<IProject | undefined>();
  const [type, setType] = useState('none');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');

  useEffect(() => {
    api.get(`/projects/allorderbycode`).then(response => {
      setProjects(response.data);
    });
  }, []);

  const getProject = (project_id: string) => {
    api.get(`/projects/id?id=${project_id}`).then(response => {
      project.current = response.data;
    });
  };

  const onConfirmProcedure = () => {
    if (type === 'cancel' && project.current) {
      history.push({
        pathname: '/management/projectediting/cancel',
        state: {
          id: project.current.id,
          code: project.current.code,
          status: project.current.status,
        },
      });
    }

    if (type === 'client_contact' && project.current) {
      history.push({
        pathname: '/management/projectediting/clientcontact',
        state: {
          id: project.current.id,
          type: project.current.type,
          code: project.current.code,
          client_contact_name: project.current.client_contact_name,
          client_contact_phone: project.current.client_contact_phone,
          client_contact_email: project.current.client_contact_email,
        },
      });
    }

    if (type === 'discount_cancellation' && project.current) {
      history.push({
        pathname: '/management/projectediting/discountcancellation',
        state: {
          id: project.current.id,
          type: project.current.type,
          code: project.current.code,
          pending_discount_price:
            project.current.net_discount_price -
            project.current.granted_discount_price -
            project.current.canceled_discount_price,
        },
      });
    }

    if (type === 'erp_rep_client' && project.current) {
      history.push({
        pathname: '/management/projectediting/erprepclient',
        state: {
          id: project.current.id,
          code: project.current.code,
          erp_rep_code: project.current.erp_rep_code,
          erp_client_code: project.current.erp_client_code,
        },
      });
    }

    if (type === 'event_date' && project.current) {
      history.push({
        pathname: '/management/projectediting/eventdate',
        state: {
          id: project.current.id,
          type: project.current.type,
          code: project.current.code,
          event_date: project.current.event_date,
        },
      });
    }

    if (type === 'status' && project.current) {
      history.push({
        pathname: '/management/projectediting/status',
        state: {
          id: project.current.id,
          code: project.current.code,
          status: project.current.status,
        },
      });
    }
  };

  const validateConfirmation = () => {
    if (!project.current) {
      setTitleNotice('Solicitação não selecionada!');
      setSubTitleNotice('Necessário selecionar solicitação válida!');
      setIsOpenNotice(true);
    } else if (
      (type === 'discount_cancellation' &&
        project.current.type !== 'PATROCINIO') ||
      (type === 'event_date' && project.current.type !== 'EVENTO')
    ) {
      setTitleNotice('Solicitação inválida!');
      setSubTitleNotice(
        'A solicitação selecionada é inválida para o tipo de operação!',
      );
      setIsOpenNotice(true);
    } else if (type === 'none') {
      setTitleNotice('Tipo não selecionado!');
      setSubTitleNotice('Necessário selecionar tipo válido!');
      setIsOpenNotice(true);
    } else {
      onConfirmProcedure();
    }
  };

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Alteração Manual de Solicitação"
        mainIcon={FaTools}
        firstChildIcon={FaWrench}
        mainColored
      />

      <div
        style={{
          maxWidth: 700,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 10,
        }}
      >
        <Wrapper style={{ width: 230 }}>
          <Select
            label="Solicitações"
            options={projects?.map(proj => ({
              value: proj.id,
              label: `${proj.code}`,
            }))}
            onSet={(e: any) => {
              if (e.value !== project.current?.id) {
                getProject(e.value);
              }
            }}
            loading={!projects}
          />
        </Wrapper>

        <Wrapper style={{ width: 230 }}>
          <Select
            label="Tipos"
            options={
              user.user_type.type !== 'ADM'
                ? TYPE_OPTIONS.filter(option => !option.adm_perm)
                : TYPE_OPTIONS
            }
            onSet={(e: any) => {
              setType(e.value);
            }}
          />
        </Wrapper>

        <Wrapper>
          <ButtonContainer>
            <SelectButton onClick={() => validateConfirmation()} />
          </ButtonContainer>
        </Wrapper>
      </div>

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default ChooseProcess;
