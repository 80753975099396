import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import Stages from '~/components/Stages';
import ExitButton from '~/components/Buttons/Exit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import { Container, TabTitle, ButtonContainer } from './styles';

interface ILocation {
  id: string;
  type: string;
  erp_rep_code: string;
  status: string;
  project_procedure: {
    sequence: number;
  };
}

const View: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/sponsorships');
    } else if (
      user.user_type.type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/sponsorships');
    }
  }, [addToast, history, state, user]);

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Visualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab>
            <TabTitle>Linha do Tempo</TabTitle>
          </Tab>
          <Tab>
            <TabTitle>Geral</TabTitle>
          </Tab>
        </TabList>

        <TabPanel>
          <Stages
            project_id={state.id}
            type={state.type}
            current_seq={state.project_procedure.sequence}
            status={state.status}
          />
        </TabPanel>

        <TabPanel>
          <ProjectInfo project_id={state.id} all />
        </TabPanel>
      </Tabs>
      <ButtonContainer>
        <Link
          to={{
            pathname: '/projects/sponsorships',
          }}
        >
          <ExitButton type="button" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default View;
