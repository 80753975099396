import React from 'react';
import { motion } from 'framer-motion';

const AnimatedDottedLine: React.FC = () => {
  return (
    <svg width="50" height="100" viewBox="0 0 50 100">
      <motion.line
        x1="25"
        y1="10"
        x2="25"
        y2="90"
        stroke="#747474"
        strokeWidth="4"
        strokeDasharray="5 10"
        initial={{ strokeDashoffset: 50 }}
        animate={{ strokeDashoffset: 0 }}
        transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}
      />
    </svg>
  );
};

export default AnimatedDottedLine;
