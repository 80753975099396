import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.background};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.shadows.flat};
    padding: 2.4rem;
    width: 100%;
    margin: 2.4rem 0;
  `}
`;

export const Wrapper = styled.div`
  @media (max-width: 900px) {
    width: 100% !important;
  }
`;
