import React from 'react';

const StaticLine: React.FC = () => {
  return (
    <svg width="50" height="100" viewBox="0 0 50 100">
      <line x1="25" y1="10" x2="25" y2="90" stroke="#adadad" strokeWidth="4" />
    </svg>
  );
};

export default StaticLine;
