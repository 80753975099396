import React from 'react';

import { Card, CustomTooltip } from './styles';

interface IStaticCardProps {
  description: string;
  pending: boolean;
  user_name?: string;
}

const StaticCard: React.FC<IStaticCardProps> = ({
  description,
  pending,
  user_name,
}) => {
  return (
    <CustomTooltip
      title={user_name ? `Usuário: ${user_name}` : ''}
      placement="right"
      arrow
    >
      <Card pending={pending}>{description}</Card>
    </CustomTooltip>
  );
};

export default StaticCard;
